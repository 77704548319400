import { Dispatch, FormEvent, SetStateAction } from 'react';
import { isEmail } from 'lib/validation';
import { ometriaIdentify } from 'lib/ometria';
import { noop } from 'lib/util';

interface SubmitHandlerArgs {
  setEmailError: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  openSignup: () => void;
  email: string;
}

export const submitHandler =
  ({ setEmailError, setEmail, openSignup, email }: SubmitHandlerArgs) =>
  (event: FormEvent) => {
    event.preventDefault();

    if (!isEmail(email)) {
      return setEmailError('Please enter a valid email');
    }

    ometriaIdentify(email);
    openSignup();
    setEmail('');
    setEmailError('');
  };

export const setEmailHandler =
  (setEmail: Dispatch<SetStateAction<string>>) => (value: string) => {
    setEmail(value);
  };

export const getEmailAction = (email: string) => (email ? noop : undefined);
