import { FooterGap, FooterLinkTitle } from 'components/Footer/Footer';
import { FooterButton } from 'components/Footer/FooterButton';
import { LinkModel } from 'models/contentful/link/types';
import RouterLink from 'next/link';
import { FC, Fragment, MouseEvent, useState } from 'react';

export interface OsanoType {
  cm: {
    showDrawer: (command: string) => void;
  };
}

declare global {
  interface Window {
    Osano?: OsanoType;
  }
}

interface FooterLinksProps {
  footerTitle: string;
  footerLinks: LinkModel[];
}

const FooterLinks: FC<FooterLinksProps> = ({ footerTitle, footerLinks }) => {
  const [columnOpen, setColumnOpen] = useState(false);

  return (
    <>
      <FooterButton
        footerTitle={footerTitle}
        columnOpen={columnOpen}
        aria-expanded={columnOpen}
        setColumnOpen={setColumnOpen}
      />
      <FooterGap showLink={columnOpen} size={{ xs: 16, l: 0 }} />

      {footerLinks.map(({ title, link }, index, arr) => (
        <Fragment key={link}>
          <RouterLink legacyBehavior={true} href={link} passHref>
            {title === 'Cookie Options' ? (
              <FooterLinkTitle
                as="a"
                variant="1"
                size="XS"
                href={link}
                showLink={columnOpen}
                onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                  event.preventDefault();
                  global?.window?.Osano?.cm?.showDrawer(
                    'osano-cm-dom-info-dialog-open'
                  );
                }}
              >
                {title}
              </FooterLinkTitle>
            ) : (
              <FooterLinkTitle
                as="a"
                variant="1"
                size="XS"
                href={link}
                showLink={columnOpen}
              >
                {title}
              </FooterLinkTitle>
            )}
          </RouterLink>
          {index === arr.length - 1 ? null : (
            <FooterGap showLink={columnOpen} size={{ xs: 0, l: 16 }} />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default FooterLinks;
