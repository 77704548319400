import { FooterLinkTitle } from 'components/Footer/Footer';
import { MinusSmall, PlusSmall } from 'components/Icons';
import { setStateCallback } from 'lib/react';
import { Dispatch, FC, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

const Button = styled.button(
  ({ theme: { bp } }) => css`
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;

    ${bp.l} {
      display: none;
    }
  `
);

interface FooterButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  footerTitle: string;
  setColumnOpen: Dispatch<SetStateAction<boolean>>;
  columnOpen: boolean;
}

export const FooterButton: FC<FooterButtonProps> = ({
  footerTitle,
  setColumnOpen,
  columnOpen,
  ...props
}) => (
  <Button
    {...props}
    onClick={setStateCallback(setColumnOpen)(!columnOpen)}
    aria-label={footerTitle}
  >
    <FooterLinkTitle variant="1" size="XS" showLink={true}>
      {footerTitle}
    </FooterLinkTitle>
    {columnOpen ? <MinusSmall /> : <PlusSmall />}
  </Button>
);
