import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Spotify,
  TikTok,
  Twitter,
} from 'components/Icons';
import { FooterModel } from 'models/contentful/footer/types';
import { SocialNetwork } from 'models/contentful/socialLink/types';
import { ComponentType, FC, createElement } from 'react';
import styled from 'styled-components';

const socialIcons: Record<SocialNetwork, ComponentType> = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: LinkedIn,
  pinterest: Pinterest,
  spotify: Spotify,
  tiktok: TikTok,
  twitter: Twitter,
};

const Container = styled.div`
  display: flex;

  > * {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const SocialLink = styled.a`
  margin-left: 32px;

  &:first-of-type {
    margin-left: 0;
  }
`;

const FooterSocialLinks: FC<Pick<FooterModel, 'socialLinks'>> = ({
  socialLinks,
}) => (
  <Container>
    {socialLinks.map(({ link, title, socialNetwork }) => (
      <SocialLink
        key={socialNetwork}
        href={link}
        title={title}
        target="_blank"
        rel="noreferrer"
      >
        {createElement(socialIcons[socialNetwork], { key: socialNetwork })}
      </SocialLink>
    ))}
  </Container>
);

export default FooterSocialLinks;
