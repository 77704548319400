import {
  AliPay,
  Amex,
  ApplePay,
  Jcb,
  Klarna,
  Maestro,
  Mastercard,
  PayPal,
  UnionPay,
  Visa,
  WeChatPay,
} from 'components/Icons';
import { PaymentMethod } from 'models/contentful/siteSettings/types';
import { ComponentType, createElement, FC } from 'react';
import styled from 'styled-components';

interface FooterPaymentMethodsProps {
  paymentMethods: PaymentMethod[];
}

const paymentIcons: Record<PaymentMethod, ComponentType> = {
  alipay: AliPay,
  amex: Amex,
  applepay: ApplePay,
  jcb: Jcb,
  klarna: Klarna,
  maestro: Maestro,
  mastercard: Mastercard,
  paypal: PayPal,
  unionpay: UnionPay,
  visa: Visa,
  wechatpay: WeChatPay,
};

const Container = styled.div`
  display: flex;

  > * {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const FooterPaymentMethods: FC<FooterPaymentMethodsProps> = ({
  paymentMethods,
}) => (
  <Container>
    {paymentMethods.map(paymentMethod =>
      createElement(paymentIcons[paymentMethod], { key: paymentMethod })
    )}
  </Container>
);

export default FooterPaymentMethods;
