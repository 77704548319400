import FooterLinks from 'components/Footer/FooterLinks';
import FooterPaymentMethods from 'components/Footer/FooterPaymentMethods';
import FooterSocialLinks from 'components/Footer/FooterSocialLinks';
import {
  getEmailAction,
  setEmailHandler,
  submitHandler,
} from 'components/Footer/helpers/util';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import { EmailInput } from 'components/Input';
import { Longform, Special, Statement, Title } from 'components/Typography';
import { format } from 'date-fns';
import { getApiLocale, guessLocale } from 'lib/locale';
import { FooterModel } from 'models/contentful/footer/types';
import { PaymentMethod } from 'models/contentful/siteSettings/types';
import RouterLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';
import { useLazyDispatch } from 'store/hooks';
import { openOverlay } from 'store/overlays';
import styled, { css } from 'styled-components';

const FooterColumn = styled(Column)`
  display: flex;
  flex-direction: column;
`;

const FooterButton = styled.button(
  ({ theme: { bp } }) => css`
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    height: 48px;
    align-items: center;

    ${bp.l} {
      height: auto;
    }
  `
);

const MobileGap = styled(Gap)(
  ({ theme: { bp } }) => css`
    ${bp.l} {
      display: none;
    }
  `
);

const Flag = styled.img`
  width: 16px;
  height: 12px;
  margin-right: 8px;
`;

interface FooterProps {
  footer?: FooterModel | null;
  paymentMethods?: PaymentMethod[];
  locale: string | null;
}

const Footer: FC<FooterProps> = ({ footer, paymentMethods = [] }) => {
  const { asPath } = useRouter();

  const lazyDispatch = useLazyDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const openSignup = useCallback(
    lazyDispatch(
      openOverlay({
        closeOnOutsideClick: true,
        name: 'newsletterForm',
        props: { email },
      })
    ),
    [email]
  );

  const openCountryPicker = useCallback(
    lazyDispatch(
      openOverlay({
        closeOnOutsideClick: true,
        name: 'countryPicker',
        props: {},
      })
    ),
    []
  );

  const locale = guessLocale({ path: asPath, window: global?.window });
  const { country, displayCurrency } = getApiLocale(locale);

  if (!footer) {
    return null;
  }

  return (
    <Grid as="footer" columns={{ xs: 6, l: 12 }}>
      <FooterColumn starts={{ xs: 1 }} spans={{ xs: 6, l: 12 }}>
        <Gap size={{ xs: 96, l: 128 }} />
      </FooterColumn>
      <FooterColumn starts={{ xs: 1 }} spans={{ xs: 6, l: 4, xl: 3 }}>
        <Title variant="2" size={{ xs: 'XS', l: 'S' }}>
          {footer.joinTitle}
        </Title>
        <Gap size={16} />
        <Statement variant="1" size={{ xs: 'XS', l: '2XS' }}>
          {footer.joinDescription}
        </Statement>
        <Gap size={32} />
        <form
          noValidate
          onSubmit={submitHandler({
            setEmailError,
            setEmail,
            openSignup,
            email,
          })}
        >
          <EmailInput
            onChange={setEmailHandler(setEmail)}
            value={email}
            border="outline"
            placeholder="Email Address"
            error={emailError}
            showError={Boolean(emailError)}
            actionTitle="Sign Up"
            action={getEmailAction(email)}
            describedById="join-t-and-cs"
          />
        </form>
        <Gap size={16} />
        <Longform
          id="join-t-and-cs"
          variant="1"
          size={{ xs: '2XS' }}
          textColor="grey6"
        >
          By subscribing to our newsletter you accept our{' '}
          <RouterLink
            legacyBehavior={true}
            href={`/${locale}/terms-and-conditions`}
            passHref
          >
            <a href={`/${locale}/terms-and-conditions`}>Terms and Conditions</a>
          </RouterLink>{' '}
          and{' '}
          <RouterLink
            legacyBehavior={true}
            href={`/${locale}/privacy-policy`}
            passHref
          >
            <a href={`/${locale}/privacy-policy`}>Privacy Policy</a>
          </RouterLink>
          .
        </Longform>
        <Gap size={32} />
        <FooterSocialLinks socialLinks={footer.socialLinks} />
        <Gap size={{ xs: 64, l: 0 }} />
      </FooterColumn>

      <FooterColumn starts={{ xs: 1, l: 5 }} spans={{ xs: 6, l: 3 }}>
        <FooterLinks
          footerTitle={footer.columnOneTitle}
          footerLinks={footer.columnOneLinks}
        />
      </FooterColumn>

      <MobileGap size={32} />

      <FooterColumn starts={{ xs: 1, l: 8 }} spans={{ xs: 6, l: 3 }}>
        <FooterLinks
          footerTitle={footer.columnTwoTitle}
          footerLinks={footer.columnTwoLinks}
        />
      </FooterColumn>

      <MobileGap size={32} />

      <FooterColumn starts={{ xs: 1, l: 11 }} spans={{ xs: 6, l: 2 }}>
        <Special variant="1" size="L">
          Change Location
        </Special>
        <Gap size={{ xs: 8, l: 16 }} />
        <FooterButton
          onClick={openCountryPicker}
          aria-label={`Change shipping location. ${country} - ${displayCurrency} selected`}
        >
          <Title as="span" variant="1" size="XS">
            <Flag
              src={`/img/flags/${country.toLowerCase()}.svg`}
              width={16}
              height={12}
              alt={`${country} flag`}
            />
            {country} / {displayCurrency}
          </Title>
        </FooterButton>
      </FooterColumn>

      <FooterColumn starts={{ xs: 1 }} spans={{ xs: 6, l: 12 }}>
        <Gap size={{ xs: 64, l: 96 }} />
        <Longform variant="1" size="2XS">
          © {format(new Date(), 'yyyy')} Perfect Moment. All rights reserved.
        </Longform>
        <Gap size={{ xs: 16, l: 8 }} />
        <FooterPaymentMethods paymentMethods={paymentMethods} />
        <Gap size={32} />
      </FooterColumn>
    </Grid>
  );
};

export default Footer;

interface FooterShowLinkProps {
  showLink: boolean;
}

export const FooterGap = styled(Gap)<FooterShowLinkProps>(
  ({ theme: { bp }, showLink }) => css`
    display: ${showLink ? 'flex' : 'none'};

    ${bp.l} {
      display: block;
    }
  `
);

export const FooterLinkTitle = styled(Title)<FooterShowLinkProps>(
  ({ theme: { bp }, showLink }) => css`
    width: 100%;
    text-decoration: none;
    display: ${showLink ? 'flex' : 'none'};
    align-items: center;
    height: 48px;

    ${bp.l} {
      display: flex;
      height: auto;
    }
  `
);
